<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <Navbar title="职称明细" :callback="back" type="SMP" />
    <van-swipe class="top-img" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          :src="require('../../../../assets/images/cert/b1.jpg')"
          class="img"
      /></van-swipe-item>
    </van-swipe>
    <van-row class="simple">
      <van-col span="24">
        <van-row class="name">
          <van-col span="6" class="tle"
            ><van-tag mark :color="COLOR_M">证书名称</van-tag></van-col
          >
          <van-col span="18">
            {{ detail.name }}
          </van-col>
        </van-row>
        <van-row class="issuer">
          <van-col span="6" class="tle"
            ><van-tag mark :color="COLOR_M">颁发机构</van-tag></van-col
          >
          <van-col span="18">
            {{ detail.issuerName }}
          </van-col>
        </van-row>
        <div class="hr"></div>
        <van-row class="service">
          <van-col span="3"
            ><svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-service"></use></svg
          ></van-col>
          <van-col span="15" class="text"
            ><span class="color-s2">专业团队，一对一定制服务</span></van-col
          >
          <van-col span="6" class="button">
            <van-button
              class="btn"
              icon="gold-coin-o"
              :color="COLOR_M"
              size="mini"
              @click="createOrder()"
              >咨询申请</van-button
            >
          </van-col>
        </van-row>
        <div class="hr"></div>
        <van-row class="service">
          <van-col span="3"
            ><svg class="icon02" aria-hidden="true">
              <use xlink:href="#icon-talk"></use></svg
          ></van-col>
          <van-col span="15" class="text"
            ><span class="color-s2">线上客服免费咨询</span></van-col
          >
          <van-col span="6" class="button">
            <van-button
              class="btn"
              icon="chat-o"
              :color="COLOR_S1"
              size="mini"
              @click="onlineChat()"
              >在线咨询</van-button
            >
          </van-col>
        </van-row>
        <!-- <van-row class="tips color-s2">
          <van-col span="4">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-notice"></use></svg
          ></van-col>
          <van-col span="20" class="text"
            >推荐人员入职成功，可获取高额奖励</van-col
          >
        </van-row> -->
      </van-col>
    </van-row>
    <van-row @click="home()">
      <van-col span="24">
        <van-image
          class="focus-img"
          :src="require('../../../../assets/images/recruit/wp-focus.jpg')"
        ></van-image>
      </van-col>
    </van-row>
    <div class="detail">
      <van-image
        class="img"
        :src="require('../../../../assets/images/cert/desc.png')"
      ></van-image>
      <div v-html="detail.description"></div>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Call
      ref="call"
      win-show="false"
      entry-show="false"
      clue-model="CERT_CONSULT_ONLINE"
    />
    <Clue ref="clue" />
    <Share ref="share" />
    <LogVisit
      ref="logVisit"
      module-type="CRT"
      module-label="CRT_DETAIL_INDEX"
    />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
