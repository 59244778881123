import { Swipe, SwipeItem, Tabs, Tab, Tag, Icon, Image, RadioGroup, Radio } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import Login from '../../intercept/Login.vue'
import LogVisit from '../../common/LogVisit.vue'
import Call from '../../common/Call.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        Login: Login,
        LogVisit: LogVisit,
        Call: Call,
        Clue: Clue,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Image.name]: Image,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio
    },
    data () {
        return {
            certCode: '',
            issuerCode: '',
            loadingShow: false,
            shareShow: false,
            detail: {},
            grade: ''
        }
    },
    mounted () {
        var query = this.$route.query
        this.certCode = query.certCode
        this.issuerCode = query.issuerCode
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.$refs.logVisit.createLog()
            this.retrieveCertDetail()
        },
        initShare () {
            var title = this.task.promoTitle
            var desc = this.task.promoDesc
            var link = 'https://moc.utopanet.com/recruit/consult?taskCode=' + this.taskCode
            if (this.role === 'SEL') {
                link = link + '&refereeType=SEL'
            }
            if (this.role === 'CLT') {
                link = link + '&refereeType=CLT'
            }
            var logo = 'https://moc.utopanet.com/static/logo.png'
            this.$refs.share.initParam(title, desc, link, logo)
        },
        async retrieveCertDetail () {
            this.loadingShow = true
            var that = this
            var pd = { certCode: that.certCode, issuerCode: this.issuerCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/cert/certAPC/retrieveCertDetail', this.$qs.stringify(pd))
            this.loadingShow = false
            if (res.status === '200') {
                this.detail = res.data
                this.createViewClue()
                this.initShare()
            }
        },
        async createOrder () {
            this.loadingShow = true
            var app = window.sessionStorage.getItem(this.SESSION_APP)
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
            var pd = {
                app: app,
                certCode: this.certCode,
                issuerCode: this.issuerCode,
                userCode: user,
                operatorCode: operator,
                sellerCode: seller,
                refereeCode: referee
            }
            const { data: res } = await this.$http.post(this.BMS_URL + '/cert/order/createOrder', this.$qs.stringify(pd))
            this.loadingShow = false
            if (res.status === '200') {
                this.createOrderClue(res.data.code)
                this.$dialog.alert({
                    title: '咨询申请',
                    message: '咨询申请成功,请耐心等待或到店咨询'
                }).then(() => {
                    this.back()
                })
            } else {
                this.$dialog.alert({
                    title: '申请失败',
                    message: res.msg
                })
            }
        },
        shareImage () {
            this.$router.push({ path: '/mde/recruit/shareImage', query: { taskCode: this.taskCode } })
        },
        onlineChat () {
            this.$refs.call.callWinShow('true')
        },
        createViewClue () {
            var param = { certCode: this.certCode, issuerCode: this.issuerCode, url: window.location.href }
            var content = '证书考培浏览'
            this.$refs.clue.createClue('CERT_VIEW_CERT', this.certCode + '-' + this.issuerCode, JSON.stringify(param), content)
        },
        createOrderClue (orderCode) {
            var param = { certCode: this.certCode, issuerCode: this.issuerCode, orderCode: orderCode, url: window.location.href }
            var content = '证书考培订单'
            this.$refs.clue.createClue('CERT_ORDER_CERT', orderCode, JSON.stringify(param), content)
        },
        back () {
            var backurl = window.sessionStorage.getItem(window.const.global.CERT_DETAIL_INDEX_BACK)
            if (backurl !== undefined && backurl !== null) {
                window.location.href = backurl
            } else {
                this.$router.push({ path: '/mde/cert/home' })
            }
        }
    }
}
